import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { reducers } from './reducer';
import { StoreModule } from '@ngrx/store';
import { Capacitor } from '@capacitor/core';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { routes } from './app/app.routes';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { InterceptorProvider } from '../../shared/src/interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from '../../shared/src/auth/auth.service';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';

if (environment.production) {
  enableProdMode();
}
addIcons(allIcons);

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    importProvidersFrom(BrowserModule, StoreModule.forRoot(reducers), IonicStorageModule.forRoot()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorProvider,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({
      scrollAssist: true,
      swipeBackEnabled: false,
    }),
  ],
}).catch((err) => console.error(err));
