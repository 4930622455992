import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, concatMap, from, map, Observable, throwError } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Auth } from '@angular/fire/auth';

@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  private readonly afAuth = inject(Auth);
  private readonly auth = inject(AuthService);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.afAuth.currentUser) {
      return next.handle(request);
    }
    return from(this.afAuth.currentUser.getIdToken()).pipe(
      concatMap((token) => {
        const req = request.clone({
          setHeaders: {
            'X-Amz-Security-Token': token,
          },
        });
        return next.handle(req).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status === 204) {
              throwError(() => 204);
            }
            return event;
          }),
          catchError((e: HttpErrorResponse) => {
            if ([403].includes(e.status)) {
              // this.auth.signOut();
            }
            return throwError(e);
          }),
        );
      }),
    );
  }
}
